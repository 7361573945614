<template>
  <div>
    <!-- search input -->
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
        }"
      >
        <b-card-body class="card-body">
          <h2 class="text-primary">隱私權政策 Privacy Policy</h2>
        </b-card-body>
      </b-card>
      <b-col class="d-flex align-items-center justify-content-center m-2">
        <b-button
          variant="primary"
          @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        >
          返回
        </b-button>
      </b-col>
    </section>
    <!--/ search input -->

    <section id="knowledge-base-content">
      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col class="kb-search-content">
          <b-card>
            <b-card-body>
              <div>
                <h1>
                  <span style="color: null"
                    ><span style="font-size: 15px"
                      >This Privacy Notice for Argus宇昇環控有限公司
                      (&quot;<strong>we</strong>,&quot;
                      &quot;<strong>us</strong>,&quot; or
                      &quot;<strong>our</strong>&quot;), describes how and why
                      we might access, collect, store, use, and/or share
                      (&quot;<strong>process</strong>&quot;) your personal
                      information when you use our services
                      (&quot;<strong>Services</strong>&quot;), including when
                      you:</span
                    ></span
                  >
                </h1>
              </div>

              <ul>
                <li>
                  <span style="font-size: 15px"
                    ><span style="font-size: 15px"
                      ><span style="color: null">Visit our website at </span
                      ><a href="https://swine.tientech.net/" target="_blank"
                        ><span style="color: null"
                          >https://swine.tientech.net/</span
                        ></a
                      ><span style="color: null"
                        ><span style="font-size: 15px"
                          ><span style="font-size: 15px"
                            >, or any website of ours that links to this Privacy
                            Notice</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
              </ul>

              <ul>
                <li>
                  <span style="color: null"
                    ><span style="font-size: 15px"
                      ><span style="font-size: 15px"
                        >Download and use our mobile application
                        (Argus監控系統)<span style="font-size: 15px"
                          ><span style="font-size: 15px"
                            ><span style="font-size: 15px"
                              ><span style="font-size: 15px">,</span></span
                            ></span
                          ></span
                        ><span style="font-size: 15px"
                          ><span style="font-size: 15px">
                            or any other application of ours that links to this
                            Privacy Notice</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </li>
              </ul>

              <div style="line-height: 1.5">
                <ul>
                  <li>
                    <span style="color: null"
                      ><span style="font-size: 15px"
                        ><span style="font-size: 15px"
                          >Engage with us in other related ways, including any
                          sales, marketing, or events</span
                        ></span
                      ></span
                    >
                  </li>
                </ul>

                <div style="line-height: 1.5">
                  <span style="color: null"
                    ><span style="font-size: 15px"
                      ><strong>Questions or concerns?&nbsp;</strong>Reading this
                      Privacy Notice will help you understand your privacy
                      rights and choices. We are responsible for making
                      decisions about how your personal information is
                      processed. If you do not agree with our policies and
                      practices, please do not use our Services. If you still
                      have any questions or concerns, please contact us at
                      qaz320232@tientech.net.</span
                    ></span
                  >
                </div>

                <div style="line-height: 1.5">&nbsp;</div>

                <div style="line-height: 1.5">
                  <span style="color: null"
                    ><span style="font-size: 15px"
                      ><span style="font-size: 15px"
                        ><span style="font-size: 15px"
                          ><strong
                            >1. WHAT INFORMATION DO WE COLLECT?</strong
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </div>

                <div id="infocollect" style="line-height: 1.5">
                  <h3>
                    <span style="color: null"
                      ><span style="font-size: 15px"
                        ><strong
                          >Personal information you disclose to us</strong
                        ></span
                      ></span
                    >
                  </h3>
                  <span style="color: null"
                    ><span style="font-size: 15px"
                      ><span style="font-size: 15px"
                        ><span style="font-size: 15px"
                          ><strong><em>In Short:</em></strong></span
                        ></span
                      ><span style="font-size: 15px"
                        ><span style="font-size: 15px"
                          ><strong><em>&nbsp;</em></strong
                          ><em
                            >We collect personal information that you provide to
                            us.</em
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                </div>

                <div style="line-height: 1.5">&nbsp;</div>

                <div style="line-height: 1.5">
                  <span style="color: null"
                    ><span style="font-size: 15px"
                      ><span style="font-size: 15px"
                        >We collect personal information that you voluntarily
                        provide to us when you register on the
                        Services,&nbsp;<span style="font-size: 15px"
                          >express an interest in obtaining information about us
                          or our products and Services, when you participate in
                          activities on the Services, or otherwise when you
                          contact us.</span
                        ></span
                      ></span
                    ></span
                  >
                </div>

                <div style="line-height: 1.5">&nbsp;</div>

                <div style="line-height: 1.5">&nbsp;</div>

                <div style="line-height: 1.5">
                  <span style="color: null"
                    ><span style="font-size: 15px"
                      ><span style="font-size: 15px"
                        ><strong>Personal Information Provided by You.</strong>
                        The personal information that we collect depends on the
                        context of your interactions with us and the Services,
                        the choices you make, and the products and features you
                        use. The personal information we collect may include the
                        following:</span
                      ></span
                    ></span
                  >
                </div>

                <ul>
                  <li>
                    <span style="color: null"
                      ><span style="font-size: 15px"
                        ><span style="font-size: 15px"
                          ><span style="font-size: 15px">names</span></span
                        ></span
                      ></span
                    >
                  </li>
                  <li>
                    <span style="color: null"
                      ><span style="font-size: 15px"
                        ><span style="font-size: 15px"
                          ><span style="font-size: 15px"
                            >phone numbers</span
                          ></span
                        ></span
                      ></span
                    >
                  </li>
                </ul>

                <div style="line-height: 1.5">&nbsp;</div>

                <div id="sensitiveinfo" style="line-height: 1.5">
                  <span style="color: null"
                    ><span style="font-size: 15px"
                      ><strong>Sensitive Information.</strong> We do not process
                      sensitive information.</span
                    ></span
                  >
                </div>

                <div style="line-height: 1.5">&nbsp;</div>

                <div style="line-height: 1.5">&nbsp;</div>

                <div style="line-height: 1.5">
                  <span style="color: null"
                    ><span style="font-size: 15px"
                      ><strong>Application Data.</strong> If you use our
                      application(s), we also may collect the following
                      information if you choose to provide us with access or
                      permission:</span
                    ></span
                  >

                  <div style="line-height: 1.5">
                    <div style="line-height: 1.5">
                      <div style="line-height: 1.5">&nbsp;</div>

                      <ul>
                        <li>
                          <span style="color: null"
                            ><span style="font-size: 15px"
                              ><em>Push Notifications.</em> We may request to
                              send you push notifications regarding your account
                              or certain features of the application(s). If you
                              wish to opt out from receiving these types of
                              communications, you may turn them off in your
                              device&#39;s settings.</span
                            ></span
                          >
                        </li>
                      </ul>

                      <div style="line-height: 1.5">
                        <span style="color: null"
                          ><span style="font-size: 15px"
                            >This information is primarily needed to maintain
                            the security and operation of our application(s),
                            for troubleshooting, and for our internal analytics
                            and reporting purposes.</span
                          ></span
                        >
                      </div>

                      <div style="line-height: 1.5">&nbsp;</div>

                      <div style="line-height: 1.5">&nbsp;</div>

                      <div style="line-height: 1.5">
                        <span style="color: null"
                          ><span style="font-size: 15px"
                            ><span style="font-size: 15px"
                              >All personal information that you provide to us
                              must be true, complete, and accurate, and you must
                              notify us of any changes to such personal
                              information.</span
                            ></span
                          ></span
                        >
                      </div>

                      <div id="infouse" style="line-height: 1.5">
                        <h2>
                          <span style="color: null"
                            ><span style="font-size: 15px"
                              ><span style="font-size: 15px"
                                ><span style="font-size: 15px"
                                  ><strong
                                    >2. HOW DO WE PROCESS YOUR
                                    INFORMATION?</strong
                                  ></span
                                ></span
                              ></span
                            ></span
                          >
                        </h2>
                        <span style="color: null"
                          ><span style="font-size: 15px"
                            ><span style="font-size: 15px"
                              ><span style="font-size: 15px"
                                ><strong><em>In Short:&nbsp;</em></strong
                                ><em
                                  >We process your information to provide,
                                  improve, and administer our Services,
                                  communicate with you, for security and fraud
                                  prevention, and to comply with law. We may
                                  also process your information for other
                                  purposes with your consent.</em
                                ></span
                              ></span
                            ></span
                          ></span
                        >
                      </div>

                      <div style="line-height: 1.5">&nbsp;</div>

                      <div style="line-height: 1.5">
                        <span style="color: null"
                          ><span style="font-size: 15px"
                            ><span style="font-size: 15px"
                              ><strong
                                >We process your personal information for a
                                variety of reasons, depending on how you
                                interact with our Services, including:</strong
                              ></span
                            ></span
                          ></span
                        >
                      </div>

                      <ul>
                        <li>
                          <span style="color: null"
                            ><span style="font-size: 15px"
                              ><span style="font-size: 15px"
                                ><strong
                                  >To facilitate account creation and
                                  authentication and otherwise manage user
                                  accounts.&nbsp;</strong
                                >We may process your information so you can
                                create and log in to your account, as well as
                                keep your account in working order.</span
                              ></span
                            ></span
                          >
                        </li>
                        <li>&nbsp;</li>
                      </ul>

                      <div style="line-height: 1.5">
                        <div style="line-height: 1.5">
                          <div style="line-height: 1.5">
                            <div style="line-height: 1.5">
                              <div style="line-height: 1.5">
                                <div style="line-height: 1.5">
                                  <div style="line-height: 1.5">
                                    <div style="line-height: 1.5">
                                      <div style="line-height: 1.5">
                                        <div style="line-height: 1.5">
                                          <div style="line-height: 1.5">
                                            <div style="line-height: 1.5">
                                              <div style="line-height: 1.5">
                                                <div style="line-height: 1.5">
                                                  <div style="line-height: 1.5">
                                                    <div
                                                      style="line-height: 1.5"
                                                    >
                                                      <div
                                                        style="line-height: 1.5"
                                                      >
                                                        <div
                                                          style="
                                                            line-height: 1.5;
                                                          "
                                                        >
                                                          <div
                                                            style="
                                                              line-height: 1.5;
                                                            "
                                                          >
                                                            <div
                                                              style="
                                                                line-height: 1.5;
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  line-height: 1.5;
                                                                "
                                                              >
                                                                <div
                                                                  style="
                                                                    line-height: 1.5;
                                                                  "
                                                                >
                                                                  <div
                                                                    id="whoshare"
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <h2>
                                                                      <span
                                                                        style="
                                                                          color: null;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                          "
                                                                          ><span
                                                                            style="
                                                                              font-size: 15px;
                                                                            "
                                                                            ><span
                                                                              style="
                                                                                font-size: 15px;
                                                                              "
                                                                              ><strong
                                                                                >3.
                                                                                WHEN
                                                                                AND
                                                                                WITH
                                                                                WHOM
                                                                                DO
                                                                                WE
                                                                                SHARE
                                                                                YOUR
                                                                                PERSONAL
                                                                                INFORMATION?</strong
                                                                              ></span
                                                                            ></span
                                                                          ></span
                                                                        ></span
                                                                      >
                                                                    </h2>
                                                                    <span
                                                                      style="
                                                                        color: null;
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                          "
                                                                          ><strong
                                                                            ><em
                                                                              >In
                                                                              Short:</em
                                                                            ></strong
                                                                          ><em
                                                                            >&nbsp;We
                                                                            may
                                                                            share
                                                                            information
                                                                            in
                                                                            specific
                                                                            situations
                                                                            described
                                                                            in
                                                                            this
                                                                            section
                                                                            and/or
                                                                            with
                                                                            the
                                                                            following
                                                                            third
                                                                            parties.</em
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    >
                                                                  </div>

                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    &nbsp;
                                                                  </div>

                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <span
                                                                      style="
                                                                        color: null;
                                                                      "
                                                                      ><span
                                                                        style="
                                                                          font-size: 15px;
                                                                        "
                                                                        >We may
                                                                        need to
                                                                        share
                                                                        your
                                                                        personal
                                                                        information
                                                                        in the
                                                                        following
                                                                        situations:</span
                                                                      ></span
                                                                    >
                                                                  </div>

                                                                  <ul>
                                                                    <li>
                                                                      <span
                                                                        style="
                                                                          color: null;
                                                                        "
                                                                        ><span
                                                                          style="
                                                                            font-size: 15px;
                                                                          "
                                                                          ><strong
                                                                            >Business
                                                                            Transfers.</strong
                                                                          >
                                                                          We may
                                                                          share
                                                                          or
                                                                          transfer
                                                                          your
                                                                          information
                                                                          in
                                                                          connection
                                                                          with,
                                                                          or
                                                                          during
                                                                          negotiations
                                                                          of,
                                                                          any
                                                                          merger,
                                                                          sale
                                                                          of
                                                                          company
                                                                          assets,
                                                                          financing,
                                                                          or
                                                                          acquisition
                                                                          of all
                                                                          or a
                                                                          portion
                                                                          of our
                                                                          business
                                                                          to
                                                                          another
                                                                          company.</span
                                                                        ></span
                                                                      >
                                                                    </li>
                                                                  </ul>

                                                                  <div
                                                                    style="
                                                                      line-height: 1.5;
                                                                    "
                                                                  >
                                                                    <div
                                                                      style="
                                                                        line-height: 1.5;
                                                                      "
                                                                    >
                                                                      <div
                                                                        style="
                                                                          line-height: 1.5;
                                                                        "
                                                                      >
                                                                        <div
                                                                          style="
                                                                            line-height: 1.5;
                                                                          "
                                                                        >
                                                                          <div
                                                                            style="
                                                                              line-height: 1.5;
                                                                            "
                                                                          >
                                                                            <div
                                                                              id="cookies"
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <h2>
                                                                                <span
                                                                                  style="
                                                                                    color: null;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                      ><span
                                                                                        style="
                                                                                          font-size: 15px;
                                                                                        "
                                                                                        ><strong
                                                                                          >4.
                                                                                          DO
                                                                                          WE
                                                                                          USE
                                                                                          COOKIES
                                                                                          AND
                                                                                          OTHER
                                                                                          TRACKING
                                                                                          TECHNOLOGIES?</strong
                                                                                        ></span
                                                                                      ></span
                                                                                    ></span
                                                                                  ></span
                                                                                >
                                                                              </h2>
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><strong
                                                                                      ><em
                                                                                        >In
                                                                                        Short:</em
                                                                                      ></strong
                                                                                    ><em
                                                                                      >&nbsp;We
                                                                                      may
                                                                                      use
                                                                                      cookies
                                                                                      and
                                                                                      other
                                                                                      tracking
                                                                                      technologies
                                                                                      to
                                                                                      collect
                                                                                      and
                                                                                      store
                                                                                      your
                                                                                      information.</em
                                                                                    ></span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    >We
                                                                                    may
                                                                                    use
                                                                                    cookies
                                                                                    and
                                                                                    similar
                                                                                    tracking
                                                                                    technologies
                                                                                    (like
                                                                                    web
                                                                                    beacons
                                                                                    and
                                                                                    pixels)
                                                                                    to
                                                                                    gather
                                                                                    information
                                                                                    when
                                                                                    you
                                                                                    interact
                                                                                    with
                                                                                    our
                                                                                    Services.
                                                                                    Some
                                                                                    online
                                                                                    tracking
                                                                                    technologies
                                                                                    help
                                                                                    us
                                                                                    maintain
                                                                                    the
                                                                                    security
                                                                                    of
                                                                                    our
                                                                                    Services
                                                                                    and
                                                                                    your
                                                                                    account,
                                                                                    prevent
                                                                                    crashes,
                                                                                    fix
                                                                                    bugs,
                                                                                    save
                                                                                    your
                                                                                    preferences,
                                                                                    and
                                                                                    assist
                                                                                    with
                                                                                    basic
                                                                                    site
                                                                                    functions.</span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              id="inforetain"
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <h2>
                                                                                <span
                                                                                  style="
                                                                                    color: null;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                      ><span
                                                                                        style="
                                                                                          font-size: 15px;
                                                                                        "
                                                                                        ><strong
                                                                                          >5.
                                                                                          HOW
                                                                                          LONG
                                                                                          DO
                                                                                          WE
                                                                                          KEEP
                                                                                          YOUR
                                                                                          INFORMATION?</strong
                                                                                        ></span
                                                                                      ></span
                                                                                    ></span
                                                                                  ></span
                                                                                >
                                                                              </h2>
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><strong
                                                                                      ><em
                                                                                        >In
                                                                                        Short:&nbsp;</em
                                                                                      ></strong
                                                                                    ><em
                                                                                      >We
                                                                                      keep
                                                                                      your
                                                                                      information
                                                                                      for
                                                                                      as
                                                                                      long
                                                                                      as
                                                                                      necessary
                                                                                      to
                                                                                      fulfill
                                                                                      the
                                                                                      purposes
                                                                                      outlined
                                                                                      in
                                                                                      this
                                                                                      Privacy
                                                                                      Notice
                                                                                      unless
                                                                                      otherwise
                                                                                      required
                                                                                      by
                                                                                      law.</em
                                                                                    ></span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    >We
                                                                                    will
                                                                                    only
                                                                                    keep
                                                                                    your
                                                                                    personal
                                                                                    information
                                                                                    for
                                                                                    as
                                                                                    long
                                                                                    as
                                                                                    it
                                                                                    is
                                                                                    necessary
                                                                                    for
                                                                                    the
                                                                                    purposes
                                                                                    set
                                                                                    out
                                                                                    in
                                                                                    this
                                                                                    Privacy
                                                                                    Notice,
                                                                                    unless
                                                                                    a
                                                                                    longer
                                                                                    retention
                                                                                    period
                                                                                    is
                                                                                    required
                                                                                    or
                                                                                    permitted
                                                                                    by
                                                                                    law
                                                                                    (such
                                                                                    as
                                                                                    tax,
                                                                                    accounting,
                                                                                    or
                                                                                    other
                                                                                    legal
                                                                                    requirements).
                                                                                    No
                                                                                    purpose
                                                                                    in
                                                                                    this
                                                                                    notice
                                                                                    will
                                                                                    require
                                                                                    us
                                                                                    keeping
                                                                                    your
                                                                                    personal
                                                                                    information
                                                                                    for
                                                                                    longer
                                                                                    than
                                                                                    the
                                                                                    period
                                                                                    of
                                                                                    time
                                                                                    in
                                                                                    which
                                                                                    users
                                                                                    have
                                                                                    an
                                                                                    account
                                                                                    with
                                                                                    us.</span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    >When
                                                                                    we
                                                                                    have
                                                                                    no
                                                                                    ongoing
                                                                                    legitimate
                                                                                    business
                                                                                    need
                                                                                    to
                                                                                    process
                                                                                    your
                                                                                    personal
                                                                                    information,
                                                                                    we
                                                                                    will
                                                                                    either
                                                                                    delete
                                                                                    or
                                                                                    anonymize
                                                                                    such
                                                                                    information,
                                                                                    or,
                                                                                    if
                                                                                    this
                                                                                    is
                                                                                    not
                                                                                    possible
                                                                                    (for
                                                                                    example,
                                                                                    because
                                                                                    your
                                                                                    personal
                                                                                    information
                                                                                    has
                                                                                    been
                                                                                    stored
                                                                                    in
                                                                                    backup
                                                                                    archives),
                                                                                    then
                                                                                    we
                                                                                    will
                                                                                    securely
                                                                                    store
                                                                                    your
                                                                                    personal
                                                                                    information
                                                                                    and
                                                                                    isolate
                                                                                    it
                                                                                    from
                                                                                    any
                                                                                    further
                                                                                    processing
                                                                                    until
                                                                                    deletion
                                                                                    is
                                                                                    possible.</span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              id="infosafe"
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <h2>
                                                                                <span
                                                                                  style="
                                                                                    color: null;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                      ><span
                                                                                        style="
                                                                                          font-size: 15px;
                                                                                        "
                                                                                        ><strong
                                                                                          >6.
                                                                                          HOW
                                                                                          DO
                                                                                          WE
                                                                                          KEEP
                                                                                          YOUR
                                                                                          INFORMATION
                                                                                          SAFE?</strong
                                                                                        ></span
                                                                                      ></span
                                                                                    ></span
                                                                                  ></span
                                                                                >
                                                                              </h2>
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><strong
                                                                                      ><em
                                                                                        >In
                                                                                        Short:&nbsp;</em
                                                                                      ></strong
                                                                                    ><em
                                                                                      >We
                                                                                      aim
                                                                                      to
                                                                                      protect
                                                                                      your
                                                                                      personal
                                                                                      information
                                                                                      through
                                                                                      a
                                                                                      system
                                                                                      of
                                                                                      organizational
                                                                                      and
                                                                                      technical
                                                                                      security
                                                                                      measures.</em
                                                                                    ></span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    >We
                                                                                    have
                                                                                    implemented
                                                                                    appropriate
                                                                                    and
                                                                                    reasonable
                                                                                    technical
                                                                                    and
                                                                                    organizational
                                                                                    security
                                                                                    measures
                                                                                    designed
                                                                                    to
                                                                                    protect
                                                                                    the
                                                                                    security
                                                                                    of
                                                                                    any
                                                                                    personal
                                                                                    information
                                                                                    we
                                                                                    process.
                                                                                    However,
                                                                                    despite
                                                                                    our
                                                                                    safeguards
                                                                                    and
                                                                                    efforts
                                                                                    to
                                                                                    secure
                                                                                    your
                                                                                    information,
                                                                                    no
                                                                                    electronic
                                                                                    transmission
                                                                                    over
                                                                                    the
                                                                                    Internet
                                                                                    or
                                                                                    information
                                                                                    storage
                                                                                    technology
                                                                                    can
                                                                                    be
                                                                                    guaranteed
                                                                                    to
                                                                                    be
                                                                                    100%
                                                                                    secure,
                                                                                    so
                                                                                    we
                                                                                    cannot
                                                                                    promise
                                                                                    or
                                                                                    guarantee
                                                                                    that
                                                                                    hackers,
                                                                                    cybercriminals,
                                                                                    or
                                                                                    other
                                                                                    unauthorized
                                                                                    third
                                                                                    parties
                                                                                    will
                                                                                    not
                                                                                    be
                                                                                    able
                                                                                    to
                                                                                    defeat
                                                                                    our
                                                                                    security
                                                                                    and
                                                                                    improperly
                                                                                    collect,
                                                                                    access,
                                                                                    steal,
                                                                                    or
                                                                                    modify
                                                                                    your
                                                                                    information.
                                                                                    Although
                                                                                    we
                                                                                    will
                                                                                    do
                                                                                    our
                                                                                    best
                                                                                    to
                                                                                    protect
                                                                                    your
                                                                                    personal
                                                                                    information,
                                                                                    transmission
                                                                                    of
                                                                                    personal
                                                                                    information
                                                                                    to
                                                                                    and
                                                                                    from
                                                                                    our
                                                                                    Services
                                                                                    is
                                                                                    at
                                                                                    your
                                                                                    own
                                                                                    risk.
                                                                                    You
                                                                                    should
                                                                                    only
                                                                                    access
                                                                                    the
                                                                                    Services
                                                                                    within
                                                                                    a
                                                                                    secure
                                                                                    environment.</span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              id="infominors"
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <h2>
                                                                                <span
                                                                                  style="
                                                                                    color: null;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                      ><span
                                                                                        style="
                                                                                          font-size: 15px;
                                                                                        "
                                                                                        ><strong
                                                                                          >7.
                                                                                          DO
                                                                                          WE
                                                                                          COLLECT
                                                                                          INFORMATION
                                                                                          FROM
                                                                                          MINORS?</strong
                                                                                        ></span
                                                                                      ></span
                                                                                    ></span
                                                                                  ></span
                                                                                >
                                                                              </h2>
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><strong
                                                                                      ><em
                                                                                        >In
                                                                                        Short:</em
                                                                                      ></strong
                                                                                    ><em
                                                                                      >&nbsp;We
                                                                                      do
                                                                                      not
                                                                                      knowingly
                                                                                      collect
                                                                                      data
                                                                                      from
                                                                                      or
                                                                                      market
                                                                                      to
                                                                                      minors.</em
                                                                                    ></span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              id="privacyrights"
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <h2>
                                                                                <span
                                                                                  style="
                                                                                    color: null;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                      ><span
                                                                                        style="
                                                                                          font-size: 15px;
                                                                                        "
                                                                                        ><strong
                                                                                          >8.
                                                                                          WHAT
                                                                                          ARE
                                                                                          YOUR
                                                                                          PRIVACY
                                                                                          RIGHTS?</strong
                                                                                        ></span
                                                                                      ></span
                                                                                    ></span
                                                                                  ></span
                                                                                >
                                                                              </h2>
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><strong
                                                                                      ><em
                                                                                        >In
                                                                                        Short:</em
                                                                                      ></strong
                                                                                    ><em
                                                                                      >&nbsp;&nbsp;You
                                                                                      may
                                                                                      review,
                                                                                      change,
                                                                                      or
                                                                                      terminate
                                                                                      your
                                                                                      account
                                                                                      at
                                                                                      any
                                                                                      time,
                                                                                      depending
                                                                                      on
                                                                                      your
                                                                                      country,
                                                                                      province,
                                                                                      or
                                                                                      state
                                                                                      of
                                                                                      residence.</em
                                                                                    ></span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              id="withdrawconsent"
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><strong
                                                                                      ><u
                                                                                        >Withdrawing
                                                                                        your
                                                                                        consent:</u
                                                                                      ></strong
                                                                                    >
                                                                                    If
                                                                                    we
                                                                                    are
                                                                                    relying
                                                                                    on
                                                                                    your
                                                                                    consent
                                                                                    to
                                                                                    process
                                                                                    your
                                                                                    personal
                                                                                    information,
                                                                                    which
                                                                                    may
                                                                                    be
                                                                                    express
                                                                                    and/or
                                                                                    implied
                                                                                    consent
                                                                                    depending
                                                                                    on
                                                                                    the
                                                                                    applicable
                                                                                    law,
                                                                                    you
                                                                                    have
                                                                                    the
                                                                                    right
                                                                                    to
                                                                                    withdraw
                                                                                    your
                                                                                    consent
                                                                                    at
                                                                                    any
                                                                                    time.
                                                                                    You
                                                                                    can
                                                                                    withdraw
                                                                                    your
                                                                                    consent
                                                                                    at
                                                                                    any
                                                                                    time
                                                                                    by
                                                                                    contacting
                                                                                    us
                                                                                    by
                                                                                    using
                                                                                    the
                                                                                    contact
                                                                                    details
                                                                                    provided
                                                                                    in
                                                                                    the
                                                                                    section
                                                                                    &quot;</span
                                                                                  ></span
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    >HOW
                                                                                    CAN
                                                                                    YOU
                                                                                    CONTACT
                                                                                    US
                                                                                    ABOUT
                                                                                    THIS
                                                                                    NOTICE?</span
                                                                                  ></span
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    >&quot;
                                                                                    below.</span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  >However,
                                                                                  please
                                                                                  note
                                                                                  that
                                                                                  this
                                                                                  will
                                                                                  not
                                                                                  affect
                                                                                  the
                                                                                  lawfulness
                                                                                  of
                                                                                  the
                                                                                  processing
                                                                                  before
                                                                                  its
                                                                                  withdrawal
                                                                                  nor,
                                                                                  when
                                                                                  applicable
                                                                                  law
                                                                                  allows,
                                                                                  will
                                                                                  it
                                                                                  affect
                                                                                  the
                                                                                  processing
                                                                                  of
                                                                                  your
                                                                                  personal
                                                                                  information
                                                                                  conducted
                                                                                  in
                                                                                  reliance
                                                                                  on
                                                                                  lawful
                                                                                  processing
                                                                                  grounds
                                                                                  other
                                                                                  than
                                                                                  consent.</span
                                                                                ></span
                                                                              >

                                                                              <h3>
                                                                                <span
                                                                                  style="
                                                                                    color: null;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><strong
                                                                                      >Account
                                                                                      Information</strong
                                                                                    ></span
                                                                                  ></span
                                                                                >
                                                                              </h3>
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                              >
                                                                                <span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  >If
                                                                                  you
                                                                                  would
                                                                                  at
                                                                                  any
                                                                                  time
                                                                                  like
                                                                                  to
                                                                                  review
                                                                                  or
                                                                                  change
                                                                                  the
                                                                                  information
                                                                                  in
                                                                                  your
                                                                                  account
                                                                                  or
                                                                                  terminate
                                                                                  your
                                                                                  account,
                                                                                  you
                                                                                  can:</span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <ul>
                                                                              <li>
                                                                                <span
                                                                                  style="
                                                                                    color: null;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    >qaz320232@tientech.net</span
                                                                                  ></span
                                                                                >
                                                                              </li>
                                                                              <li>
                                                                                <span
                                                                                  style="
                                                                                    color: null;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    >Contact
                                                                                    us
                                                                                    using
                                                                                    the
                                                                                    contact
                                                                                    information
                                                                                    provided.</span
                                                                                  ></span
                                                                                >
                                                                              </li>
                                                                            </ul>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  >Upon
                                                                                  your
                                                                                  request
                                                                                  to
                                                                                  terminate
                                                                                  your
                                                                                  account,
                                                                                  we
                                                                                  will
                                                                                  deactivate
                                                                                  or
                                                                                  delete
                                                                                  your
                                                                                  account
                                                                                  and
                                                                                  information
                                                                                  from
                                                                                  our
                                                                                  active
                                                                                  databases.
                                                                                  However,
                                                                                  we
                                                                                  may
                                                                                  retain
                                                                                  some
                                                                                  information
                                                                                  in
                                                                                  our
                                                                                  files
                                                                                  to
                                                                                  prevent
                                                                                  fraud,
                                                                                  troubleshoot
                                                                                  problems,
                                                                                  assist
                                                                                  with
                                                                                  any
                                                                                  investigations,
                                                                                  enforce
                                                                                  our
                                                                                  legal
                                                                                  terms
                                                                                  and/or
                                                                                  comply
                                                                                  with
                                                                                  applicable
                                                                                  legal
                                                                                  requirements.</span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><strong
                                                                                      ><u
                                                                                        >Cookies
                                                                                        and
                                                                                        similar
                                                                                        technologies:</u
                                                                                      ></strong
                                                                                    >
                                                                                    Most
                                                                                    Web
                                                                                    browsers
                                                                                    are
                                                                                    set
                                                                                    to
                                                                                    accept
                                                                                    cookies
                                                                                    by
                                                                                    default.
                                                                                    If
                                                                                    you
                                                                                    prefer,
                                                                                    you
                                                                                    can
                                                                                    usually
                                                                                    choose
                                                                                    to
                                                                                    set
                                                                                    your
                                                                                    browser
                                                                                    to
                                                                                    remove
                                                                                    cookies
                                                                                    and
                                                                                    to
                                                                                    reject
                                                                                    cookies.
                                                                                    If
                                                                                    you
                                                                                    choose
                                                                                    to
                                                                                    remove
                                                                                    cookies
                                                                                    or
                                                                                    reject
                                                                                    cookies,
                                                                                    this
                                                                                    could
                                                                                    affect
                                                                                    certain
                                                                                    features
                                                                                    or
                                                                                    services
                                                                                    of
                                                                                    our
                                                                                    Services.
                                                                                  </span></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  >If
                                                                                  you
                                                                                  have
                                                                                  questions
                                                                                  or
                                                                                  comments
                                                                                  about
                                                                                  your
                                                                                  privacy
                                                                                  rights,
                                                                                  you
                                                                                  may
                                                                                  email
                                                                                  us
                                                                                  at
                                                                                  qaz320232@tientech.net.</span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              id="DNT"
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <h2>
                                                                                <span
                                                                                  style="
                                                                                    color: null;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                      ><span
                                                                                        style="
                                                                                          font-size: 15px;
                                                                                        "
                                                                                        ><strong
                                                                                          >9.
                                                                                          DO
                                                                                          WE
                                                                                          MAKE
                                                                                          UPDATES
                                                                                          TO
                                                                                          THIS
                                                                                          NOTICE?</strong
                                                                                        ></span
                                                                                      ></span
                                                                                    ></span
                                                                                  ></span
                                                                                >
                                                                              </h2>
                                                                            </div>

                                                                            <div
                                                                              id="policyupdates"
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><em
                                                                                      ><strong
                                                                                        >In
                                                                                        Short:&nbsp;</strong
                                                                                      >Yes,
                                                                                      we
                                                                                      will
                                                                                      update
                                                                                      this
                                                                                      notice
                                                                                      as
                                                                                      necessary
                                                                                      to
                                                                                      stay
                                                                                      compliant
                                                                                      with
                                                                                      relevant
                                                                                      laws.</em
                                                                                    ></span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    >We
                                                                                    may
                                                                                    update
                                                                                    this
                                                                                    Privacy
                                                                                    Notice
                                                                                    from
                                                                                    time
                                                                                    to
                                                                                    time.
                                                                                    The
                                                                                    updated
                                                                                    version
                                                                                    will
                                                                                    be
                                                                                    indicated
                                                                                    by
                                                                                    an
                                                                                    updated
                                                                                    &quot;Revised&quot;
                                                                                    date
                                                                                    at
                                                                                    the
                                                                                    top
                                                                                    of
                                                                                    this
                                                                                    Privacy
                                                                                    Notice.
                                                                                    If
                                                                                    we
                                                                                    make
                                                                                    material
                                                                                    changes
                                                                                    to
                                                                                    this
                                                                                    Privacy
                                                                                    Notice,
                                                                                    we
                                                                                    may
                                                                                    notify
                                                                                    you
                                                                                    either
                                                                                    by
                                                                                    prominently
                                                                                    posting
                                                                                    a
                                                                                    notice
                                                                                    of
                                                                                    such
                                                                                    changes
                                                                                    or
                                                                                    by
                                                                                    directly
                                                                                    sending
                                                                                    you
                                                                                    a
                                                                                    notification.
                                                                                    We
                                                                                    encourage
                                                                                    you
                                                                                    to
                                                                                    review
                                                                                    this
                                                                                    Privacy
                                                                                    Notice
                                                                                    frequently
                                                                                    to
                                                                                    be
                                                                                    informed
                                                                                    of
                                                                                    how
                                                                                    we
                                                                                    are
                                                                                    protecting
                                                                                    your
                                                                                    information.</span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              id="contact"
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <h2>
                                                                                <span
                                                                                  style="
                                                                                    color: null;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                      ><span
                                                                                        style="
                                                                                          font-size: 15px;
                                                                                        "
                                                                                        ><strong
                                                                                          >10.
                                                                                          HOW
                                                                                          CAN
                                                                                          YOU
                                                                                          CONTACT
                                                                                          US
                                                                                          ABOUT
                                                                                          THIS
                                                                                          NOTICE?</strong
                                                                                        ></span
                                                                                      ></span
                                                                                    ></span
                                                                                  ></span
                                                                                >
                                                                              </h2>
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    >If
                                                                                    you
                                                                                    have
                                                                                    questions
                                                                                    or
                                                                                    comments
                                                                                    about
                                                                                    this
                                                                                    notice,
                                                                                    you
                                                                                    may
                                                                                    <span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                      ><span
                                                                                        style="
                                                                                          font-size: 15px;
                                                                                        "
                                                                                        >contact
                                                                                        us
                                                                                        by
                                                                                        post
                                                                                        at:</span
                                                                                      ></span
                                                                                    ></span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                      >宇昇環控有限公司</span
                                                                                    ></span
                                                                                  ></span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  >雲林縣元長鄉下寮村東興路56-2號</span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  >__________</span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  >Taiwan</span
                                                                                ></span
                                                                              >
                                                                            </div>

                                                                            <div
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              &nbsp;
                                                                            </div>

                                                                            <div
                                                                              id="request"
                                                                              style="
                                                                                line-height: 1.5;
                                                                              "
                                                                            >
                                                                              <h2>
                                                                                <span
                                                                                  style="
                                                                                    color: null;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    ><span
                                                                                      style="
                                                                                        font-size: 15px;
                                                                                      "
                                                                                      ><span
                                                                                        style="
                                                                                          font-size: 15px;
                                                                                        "
                                                                                        ><strong
                                                                                          >11.
                                                                                          HOW
                                                                                          CAN
                                                                                          YOU
                                                                                          REVIEW,
                                                                                          UPDATE,
                                                                                          OR
                                                                                          DELETE
                                                                                          THE
                                                                                          DATA
                                                                                          WE
                                                                                          COLLECT
                                                                                          FROM
                                                                                          YOU?</strong
                                                                                        ></span
                                                                                      ></span
                                                                                    ></span
                                                                                  ></span
                                                                                >
                                                                              </h2>
                                                                              <span
                                                                                style="
                                                                                  color: null;
                                                                                "
                                                                                ><span
                                                                                  style="
                                                                                    font-size: 15px;
                                                                                  "
                                                                                  ><span
                                                                                    style="
                                                                                      font-size: 15px;
                                                                                    "
                                                                                    >Based
                                                                                    on
                                                                                    the
                                                                                    applicable
                                                                                    laws
                                                                                    of
                                                                                    your
                                                                                    country,
                                                                                    you
                                                                                    may
                                                                                    have
                                                                                    the
                                                                                    right
                                                                                    to
                                                                                    request
                                                                                    access
                                                                                    to
                                                                                    the
                                                                                    personal
                                                                                    information
                                                                                    we
                                                                                    collect
                                                                                    from
                                                                                    you,
                                                                                    details
                                                                                    about
                                                                                    how
                                                                                    we
                                                                                    have
                                                                                    processed
                                                                                    it,
                                                                                    correct
                                                                                    inaccuracies,
                                                                                    or
                                                                                    delete
                                                                                    your
                                                                                    personal
                                                                                    information.
                                                                                    You
                                                                                    may
                                                                                    also
                                                                                    have
                                                                                    the
                                                                                    right
                                                                                    to
                                                                                    withdraw
                                                                                    your
                                                                                    consent
                                                                                    to
                                                                                    our
                                                                                    processing
                                                                                    of
                                                                                    your
                                                                                    personal
                                                                                    information.
                                                                                    These
                                                                                    rights
                                                                                    may
                                                                                    be
                                                                                    limited
                                                                                    in
                                                                                    some
                                                                                    circumstances
                                                                                    by
                                                                                    applicable
                                                                                    law.
                                                                                    To
                                                                                    request
                                                                                    to
                                                                                    review,
                                                                                    update,
                                                                                    or
                                                                                    delete
                                                                                    your
                                                                                    personal
                                                                                    information,
                                                                                    please
                                                                                    fill
                                                                                    out
                                                                                    and
                                                                                    submit
                                                                                    a
                                                                                    email</span
                                                                                  ></span
                                                                                >.</span
                                                                              >
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <b-row>
      <b-col class="d-flex align-items-center justify-content-center m-2">
        <b-button
          variant="primary"
          @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        >
          返回
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      kb: [],
    }
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower =
        this.knowledgeBaseSearchQuery.toLowerCase()
      return this.kb.filter(
        item =>
          item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) ||
          item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower)
      )
    },
  },
  created() {},
  methods: {
    hasHistory() {
      return window.history.length > 2
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
